.ContainerAccept {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  margin-bottom: 15px;
  padding: 0 16px;
}

.Back {
  display: flex;
  position: absolute;
  left: 25px;
  outline: none;
  cursor: pointer;
  width: 200px;
}

.Back>p {
  margin: 0 0 0 6px;
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
}

.CenterContainer {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: calc(100% - 48px);
  border-radius: 32px;
  border: solid 1px #BFBFBF;
  background-color: #ffffff;
  outline: none;
}

.SubmitButton {
  width: auto;
  margin: 0;
}

.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Item {
  display: flex;
  flex-direction: column;
}

.ItemSecond {
  display: flex;
  margin-top: 26px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
}

.Dates {
  text-transform: capitalize;
  font-family: Inter Regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
}

.Center {
  margin: 0;
  font-family: Inter Regular;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.0025em;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Address {
  font-family: Inter Regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
}

.PreloadStep {
  margin: 0;
  font-family: Inter Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #1e1e1e;
  position: absolute;
  display: block;
  text-align: center;
  margin-top: 24px;
  width: 100%;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 2;
}

.Icon {
  height: 40px;
  width: 40px;
}

.ContainerLoader {
  margin: 50px auto 0 auto;
}

.Error {
  color: red;
  margin: 20px 10px;
  font-family: "ModernEra";
  text-align: center;
}

.TitleName {
  margin: 0;
  font-family: Work Sans Bold;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
  max-width: 350px;
}

.promoAppointmentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.promoAppointmentTitleContainer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

}

.promoAppointmentTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 97px;
  height: 24px;
  background: #E7F3EE;
  border-radius: 100px;
  flex: none;
  flex-grow: 0;
}

.promoAppointmentTextContainer span {
  width: 8px;
  height: 8px;
  background: #05A357;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
  border-radius: 50%;
}

.promoAppointmentTextContainer p {
  width: 75px;
  height: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: #156A41;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.IconContainer {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.Icon {
  height: 24px;
  width: 24px;
  margin: 4px 12px 0 0;
}

.MoonIcon {
  height: 20px;
  width: auto;
  margin-left: 12px;
}

.AddressPrimaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.Info {
  margin: 0 auto 20px auto;
  font-family: Inter Regular;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
  max-width: 228px;

}

.ContainerButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin: 32px 0 0 0;
}

@media(max-width: 350px) {
  .CenterContainer {
    padding: 28px 10px;
  }
}

@media(min-width: 700px) {
  .CenterContainer {
    max-width: 631px;
    padding: 32px;
    margin-top: 56px;
  }

  .AddressContainer {
    display: flex;
    flex-direction: column;
  }

  .InfoDate {
    display: flex;
    flex-direction: column;
    margin-left: 66px;
  }

  .ContainerAccept {
    width: 100%;
    margin-bottom: 83px;
    padding: 0;
  }

  .ContainerButton {
    gap: 24px;
    padding: 0 24px;
    flex-direction: row;
    margin: 0;
  }

  .SubmitButton {
    width: 100%;
    max-width: 243px;
    margin: 32px auto 0 auto;
  }

  .TitleName {
    margin: 0;
    font-family: Work Sans Bold;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    max-width: 700px;
  }

  .Info {
    margin-bottom: 20px;
    font-family: Inter Regular;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #000000;
    max-width: 100%;
  }

  .Center {
    font-family: Inter Regular;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;  
    text-align: left;
  }

  .MoonIcon {
    height: 37px;
    width: 43px;
    margin-left: 12px;
  }
}