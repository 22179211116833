.popup-content {
    margin: auto;
    background: #f1f1f0;
    width: 70%;
    max-width: 644px;
    padding: 5px;
    border-radius: 21px;
    font-family: "Modern Era", sans-serif;
    text-align: center;
}
.modal-image{
    position: absolute;
    width: 50%;
    top: -16%;
    right: 25%;
}

.actions{
    justify-content: center;
    display: flex
}

.bold{
    font-family: ModernEraBold, sans-serif;
}

.image{
    width: 100%;
    max-width: 152px;
}

.content-wrapper{
    display: flex;
    flex-direction: column;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}
.close{
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 12px;
    color: #ad9191;
}
.content-texts{
    margin-top: 10%;
}
.header{
    font-family: ModernEraBold, sans-serif;
}

.header-text{
    margin: 0;
    font-size: 20px;
    line-height: 22px;
}

.content{
    margin: 20px 0;
    font-size: 16px;
    line-height: 19px;
}

.modal{
    margin: 34px auto;
    width: 80%;
}

.cta{
    border:none;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    background-color: #1245f3;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: ModernEraBold, sans-serif;
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}
.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
@media (min-width: 370px){
    .content-texts{
        margin-top: 50px;
    }
}

@media (min-width: 400px){
    .content-texts{
        margin-top: 70px;
    }
}

@media (min-width: 767px) {
    .modal{
        width: 90%;
    }
    .modal-image{
        position: relative;
        width: 100%;
        max-width: 283px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content-wrapper{
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    .image{
        max-width: none;
    }
    .cta{
        max-width: 219px;
    }
    .content-texts{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 11px;
        max-width: 276px;
    }
    .header-text{
        font-size: 24px;
        line-height: 26px;
    }
    .content{
        font-size: 17px;
        margin: 0;
    }
    .close{
        right: 20px;
        top: 20px;
    }
}
