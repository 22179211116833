body{
    margin: 0;
    -webkit-font-smoothing: subpixel-antialiased;
}
* {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}

.DDMainContainer {
    width: 100%!important;
    min-width: 100px!important;
}
.DDItemActive, .DDListItem:hover {
    background-color: #FDE9ED;
}
.DDListItem:has(.DDItemSelected) {
    background-color: #FDE9ED;
}
.DDListItem  .DDItemSelected {
    display: none;
}
.DDListItem:has(.DDItemSelected)>button {
    color: #E61E4D;
}
.DDItemActive, .DDListItem:hover>button {
    color: #E61E4D;
}
.DDListSingle {
    overflow-y: auto;
}
.DDList { 
    box-shadow: none;
    box-sizing: none;
    margin-top: 0;    
    border: 1px solid #BFBFBF;
    border-top: none;
    border-radius: 0 0 8px 8px;
    width: calc(100% + 2px);
    margin-left: -1px;
}

.DDContainerActive {
    border: solid 1px #BFBFBF;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
}
@font-face {
    font-family: 'ModernEra';
    src: url("./Assets/fonts/ModerEra/ModernEraRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMono';
    src: url("./Assets/fonts/ModerEra/ModernEraMonoRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMonoBold';
    src: url("./Assets/fonts/ModerEra/ModernEraMonoBold.ttf");
}

@font-face {
    font-family: 'ModernEraBold';
    src: url("./Assets/fonts/ModerEra/ModernEraBold.ttf");
}

@font-face {
    font-family: 'CooperBT';
    src: url("./Assets/fonts/Cooper/CooperLtBT-Regular.ttf");
}

@font-face {
    font-family: 'QuincyCFBold';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Bold.woff");
}

@font-face {
    font-family: 'QuincyCFMedium';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Medium.woff");
}

@font-face {
    font-family: 'QuincyCF';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Regular.woff");
}

@font-face {
    font-family: 'QuincyCFItalic';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-RegularItalic.woff");
}

@font-face {
    font-family: 'Inter Regular';
    src: url("./Assets/fonts/Inter/Inter-Regular.ttf");
}

@font-face {
    font-family: 'Inter Bold';
    src: url("./Assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
    font-family: 'Poppins Medium';
    src: url("./Assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: 'Poppins Bold';
    src: url("./Assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
    font-family: 'Work Sans Regular';
    src: url("./Assets/fonts/WorkSans/WorkSans-Regular.ttf");
}

@font-face {
    font-family: 'Work Sans Bold';
    src: url("./Assets/fonts/WorkSans/WorkSans-Bold.ttf");
}