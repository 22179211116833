.ContainerDates {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.ContainerLoader {
  margin-top: 100px;
  height: 60vh;
}

.discount {
  width: 24px;
  left: 10px;
  top: 3px;
  position: relative;
}

.CenterContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
  margin: 32px 24px 8px 24px;
  padding: 16px 16px;
  border-radius: 32px;
  border: solid 1px #BFBFBF;
  background-color: #ffffff;
  outline: none;
}

.Select {
  margin: 24px 0 0 0;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  color: #616161;
}

.Timing {
  margin-top: 10px;
  width: 100%;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #003fff;
}

.IconAddress {
  height: 20px;
  width: 20px;
  margin: 2px 4px 0 0;
}

.IconContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
}

.Address {
  margin: 0;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #424242;
}

.Center {
  margin: 8px 0 4px 0;
  font-family: Work Sans Bold;
  font-size: 16px;
  font-weight: normal;
  line-height: 18.77px;
  text-align: center;
  color: #000000;
  padding: 4px 8px;
}

.Icon {
  height: 40px;
  width: 40px;
}

.DatesContainer {
  margin-top: 40px;
  width: 100%;
  position: relative;
}

.Item>p.Label {
  text-transform: capitalize;
  font-family: Inter Regular;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  color: #616161;
  margin: 0;
}

.Item>p.Label2 {
  text-transform: capitalize;
  font-family: Inter Regular;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  color: #000000;
}

.AllDates {
  width: 100%;
  max-width: 900px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
}

.ImageArrow {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFDBE3;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  width: 10.3px;
  height: 10.3px;
  margin-top: 16px;
  position: absolute;
}

.AllDates .RightArrow {
  right: 0;
}

.ContainerDesktop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 24px;
  margin-top: 5px;
}

.bookingPriorityContainer {
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.priorityDatesContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
  margin-top: 5px;
}

.ContainerMobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Item>p {
  margin: 0 0 12px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Time {
  margin-top: 10px;
  padding: 10px 18px;
  font-family: Inter Regular;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-radius: 10px;
  border: solid 1px #EEEEEE;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}

.Time:active {
  background-color: #FFDBE3;
  border: solid 1px #E61E4D;
}

.ContainerTimes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
}

.ImageArrow img {
  width: 11.2px;
  height: 15.27px;
  outline: none;
}

.BackContainer {
  width: 100%;
  max-width: 900px;
}

.Back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  cursor: pointer;
  width: fit-content;
}

.Left {
  margin-right: 1px;
}

.Right {
  margin-left: 1px;
}

.Back>p {
  margin: 0 0 0 12px;
  font-family: Inter Regular;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.TitleName {
  margin: 0;
  font-family: Work Sans Bold;
  font-size: 18px;
  font-weight: normal;
  line-height: 21.11px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
}

.Subtitle {
  margin: 0;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  color: #616161;
}


.EmptyDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 396px;
  background-color: #f7f9ff;
}

.EmptyDate>p {
  margin: 25px 0 0;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDateDesk>p {
  margin: 0 5px;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDate>img {
  margin-top: 58px;
}

.EmptyDateDesk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 396px;
  background-color: #f7f9ff;
}

.promoAppointmentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.promoAppointmentTitleContainer {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;

}

.promoAppointmentTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  width: 103px;
  height: 24px;
  background: #E7F3EE;
  border-radius: 100px;

  flex: none;
  flex-grow: 0;
  /* margin-left: 46%; */
}

.promoAppointmentTextContainer span {
  width: 8px;
  height: 8px;
  background: #05A357;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
  border-radius: 50%;
}

.promoAppointmentTextContainer p {
  width: 75px;
  height: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.004em;
  color: #156A41;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}


.EmptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 48px);
}

.EmptyContainer p.bookingTitle {
  font-family: Work Sans Bold;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
  text-align: center;
  margin: 24px 0 24px 0;
  color: #616161;
}

.EmptyContainer p.bookingDescription {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.0125em;
  text-align: center;
  color: #616161;
  margin-bottom: 24px;
  max-width: 485px;
}

.EmptyContainer>img {
  width: 32.77px;
  height: 28.4px;
  margin-left: 0;
}

@media(max-width: 350px) {
  .EmptyDate {
    width: 260px;
  }

}


@media(min-width: 700px) {

  .CenterContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    min-width: 600px;
    padding: 16px 16px;
    border-radius: 32px;
    border: solid 1px #BFBFBF;
    background-color: #ffffff;
    outline: none;
  }


  .TitleName {
    margin: 0;
    font-family: Work Sans Bold;
    font-size: 32px;
    font-weight: normal;
    line-height: 48px;
    letter-spacing: -0.0025em;
    text-align: center;
    color: #000000;
  }

  .Center {
    margin: 12px 0;
    font-family: Work Sans Bold;
    font-size: 24px;
    font-weight: normal;
    line-height: 36px;
    padding: 4px 8px;
  }

  .IconAddress {
    height: 24px;
    width: 24px;
    margin: 2px 4px 0 0;
  }

  .Address {
    margin: 0;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #424242;
  }


  .ContainerDates {
    margin-bottom: 83px;
  }


  .EmptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 286px);
  }

  .EmptyContainer p.bookingTitle {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    max-width: 485px;
  }

  .EmptyContainer>img {
    width: 43.05px;
    height: 37.31px;
  }


  .ImageArrow {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFDBE3;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    width: 10.3px;
    height: 10.3px;
    margin-top: 16px;
    position: inherit;
  }

  .AllDates .RightArrow {
    right: 0;
  }

  .Time:hover {
    background-color: #FFDBE3;
    border: solid 1px #E61E4D;
  }
}

.bookingTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 18px;
}

.bookingDescription {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.0125em;
  text-align: center;
  color: #616161;
  margin-bottom: 24px;
}

.bookingCta {
  text-decoration: none;
  border: none;
  border-radius: 10px;
  background: #0752F9;
  box-shadow: 0px 4px 4px rgba(7, 82, 249, 0.16);
  margin-top: 16.5px;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: center;
  width: 235px;
  padding: 10px 2px;
}

.bookingCtaDisabled {
  text-decoration: none;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(7, 82, 249, 0.16);
  background-color: #87ABFB;
  margin-top: 17px;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: center;
  width: 267px;
  padding: 10px 2px;
}


.PopupCard {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  width: 420px;
  margin: auto 24px;
  max-height: 50%;
}

.PopupCard img {
  margin-top: 10px;
  width: 77px;
}

.PopupTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 540;
  font-size: 28px;
  line-height: 48px;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 40px;
}

.PopupDesc {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
}

.PopupBtn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 8px;
  background-color: #0752f9;
  font-family: 'Inter';
  font-weight: 520;
  font-size: 16px;
  line-height: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 24px;
  max-width: 80%;
  width: 332px;
}

.PopupLink {
  margin-top: 24px;
  text-decoration: underline;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0125em;
  text-align: left;

}

.MoonIcon {
  height: 37px;
  width: 43px;
  margin-top: 24px;
}

.Spinner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 420px) {
  .PopupTitle {
    font-size: 23px;
  }

  .PopupCard {
    max-height: 100%;
  }
}

@media (max-width: 700px) {
  .Time {
    font-size: 14px;
    padding: 10px 8px;
    margin: 4px;
  }

  .Item>p.Label {
    font-size: 14px;
  }

  .Item>p.Label2 {
    font-size: 16px;
  }

  .discount {
    width: 16px;
    left: 4px;
  }

  .BackContainer {
    padding: 0 8px;
    width: calc(100% - 16px);
  }

  .Select {
    font-size: 16px;
    line-height: 24px;
  }

  .DatesContainer {
    margin: 24px 0 0 0;
    padding: 0 4px;
    width: calc(100% - 8px);
  }
}

@media (max-width: 375px) {
  .ContainerDates {
    padding: 0 2px !important;
    width: calc(100% - 4px) !important;
  }

  .discount {
    width: 14px !important;
    left: 2px !important;
    top: 1px !important;
  }
}

@media (max-width: 1240px) {
  .ContainerDates {
    padding: 0 8px;
    width: calc(100% - 16px);
  }
}