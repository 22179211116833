.CounterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  border-radius: 11px;
  background-color: #EEF6F0;
  font-family: 'Inter Regular';
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.005em;
  margin-top: 32px;
  color: #000000;
  width: calc(100% - 48px);
}

.CounterContainer>p:last-child {
  margin: 0 10px;
  font-family: 'Inter Bold';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 36px;
  letter-spacing: normal;
  text-align: center;
  color: #E61E4D;
}

.TimeExpired {
  border-radius: 10px;
  background-color: #fcf6b5;
}

.Counter {
  margin-top: 16px !important;
}

.PopupCard {
  padding: 0 24px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 511px;
  width: 100%;
  margin: auto 24px;
  min-height: 403px;
}

.PopupTitle {
  font-family: Work Sans Bold;
  font-size: 24px;
  font-weight: normal;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
}

.PopupDesc {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: center;
  color: #616161;
  margin-top: 16px;
}

.PopupBtn {
  width: calc(100% - 32px);
  margin-top: 48px;
}

.TimerIconAlert {
  object-fit: contain;
  width: 32px;
  height: 32px;
}

@media(max-width: 350px) {
  .CounterContainer {
    max-width: 280px;
  }
}

@media(max-width: 700px) {
  .PopupCard {
    min-height: 403px;
    width: calc(100% - 48px);
  }
}

@media(min-width: 700px) {

  .CounterContainer {
    font-family: 'Inter Regular';
    font-size: 24px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    margin-top: 32px;
  }


  .PopupCard {
    margin: 0;
  }

  .PopupTitle {
    font-family: Work Sans Bold;
    font-size: 32px;
    font-weight: normal;
    line-height: 48px;
    letter-spacing: -0.0025em;
    text-align: center;
    color: #000000;
    margin-top: 16px;
  }

  .PopupBtn {
    max-width: 391px;
  }

  .TimerIconAlert {
    object-fit: contain;
    width: 48px;
    height: 48px;
  }

  .PopupDesc {
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0015em;
    text-align: center;
    color: #616161;
    margin-top: 16px;
  }
}