.ContainerSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.Cosmonaut {
  width: 166px;
}

.Thanks {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0f7e7;
  width: 100%;
  margin-top: 37.6px;
  max-width: 683px;
}

.ThanksContainer {
  display: flex;
  align-items: center;
  margin: 30px 45px;
}

.IconContainer {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.Icon {
  height: 24px;
  width: 24px;
  margin: 4px 12px 0 0;
}

.MoonIcon {
  height: 37px;
  width: 43px;
  margin-left: 0;
  margin-bottom: 0;
}

.Dates {
  text-transform: capitalize;
  font-family: Inter Regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
}

.Center {
  margin: 0;
  font-family: Inter Regular;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.Address {
  font-family: Inter Regular;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
}

.MapContainer {
  display: flex;
  outline: none;
  cursor: pointer;
  max-width: 600px;
}

.MapImagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.SeeMaps {
  display: flex;
  cursor: pointer;
  outline: none;
  margin-top: 33px;
}

.SeeMaps>p {
  margin: 0;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  text-decoration: underline
}

.MapImage {
  width: 100%;
  border-radius: 10px;
  max-width: 350px;
  height: intrinsic;
}

.IconMap {
  margin-right: 12.3px;
}

.ThanksText {
  margin: 28px 0 0 0;
  font-family: Work Sans Bold;
  font-size: 32px;
  font-weight: normal;
  line-height: 48px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
  max-width: 438px;
}

.Ok {
  width: 64px;
  margin-right: 26px;
}

.CenterContainer {
  margin: 24px auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: calc(100% - 32px);
  border-radius: 32px;
  border: solid 1px #BFBFBF;
  background-color: #ffffff;
  outline: none;
}


.Info {
  margin: 0 auto 32px auto;
  font-family: Work Sans Regular;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
  max-width: 228px;

}

.Item {
  display: flex;
  flex-direction: column;
}

.Icon {
  width: 20.8px;
}

.IconAdvice {
  width: 18.95px;
  margin-left: 24px;
  margin-top: -96px;
}

.Name {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
}

.ItemSecond {
  display: flex;
  margin-top: 24px;
}

.ContainerLinkCalendar {
  display: flex;
  margin-top: 37.8px;
}

.ImageCalendar {
  width: 9.8px;
  margin-right: 8.4px;
}

.LinkCalendar {
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
}

.Section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ResumeLink {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
  padding: 0 16px;
  align-items: center;
  margin-top: 29px;
}

.Recommendation {
  display: flex;
  border-radius: 10px;
  background-color: #fceed2;
  margin-top: 43px;
}

.ContainerRec {
  display: flex;
  flex-direction: column;
  margin: 16px 25px;
}

.TitleRecommendation {
  font-family: Inter Bold;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #000000;
  margin: 0;
}

.ItemRec {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #242424;
  margin: 16px 0 0;
}

.NewAppointment {
  border-radius: 10px;
  border: solid 3px #003fff;
  background-color: #ffffff;
  padding: 17px 50px;
  font-family: ModernEraBold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #003fff;
  margin-top: 24.5px;
  outline: none;
  cursor: pointer;
}

.Advice {
  display: flex;
  border-radius: 8px 0px 0px 0px;
  background-color: #FEF3C7;
  margin-top: 32px;
  width: 100%;
  border-radius: 8px;
}

.ButtonUserDashboard {
  margin: 36px 0 0 0;
  width: 230px;
}

.ButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;
  gap: 12px;
  padding: 0;
  width: 100%;
}

@media(max-width: 350px) {
  .CenterContainer {
    max-width: 224px;
  }

  .Recommendation {
    max-width: 280px;
  }


  .ThanksContainer {
    margin: 30px 30px;
  }

  .MapImage {
    max-width: 280px;
  }
}

.TextFooter {
  margin: 36px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

@media(min-width: 700px) {

  .Ok {
    width: 86px;
    margin-right: 50px;
  }

  .AddressContainer {
    display: flex;
    flex-direction: column;
  }

  .ResumeLink {
    margin-top: 34px;
    width: 100%;
    padding: 0;
  }

  .Section {
    flex-direction: row;
    max-width: 735px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Recommendation {
    margin-top: 32px;
  }

  .ThanksContainer {
    margin: 33px 49px;
  }

  .ThanksText {
    margin: 0;
    font-family: Work Sans Bold;
    font-size: 40px;
    font-weight: normal;
    line-height: 56px;
    letter-spacing: -0.0025em;
    text-align: center;
    color: #000000;
    max-width: 438px;
  }

  .MapImage {
    max-width: 100%;
  }

  .IconAdvice {
    margin-top: -60px;
  }

  .CenterContainer {
    margin: 24px auto 0 auto;
    max-width: 561px;
    padding: 32px;
    margin-top: 16px;
    margin-bottom: 112px;
  }

  .Info {
    margin-bottom: 32px;
    font-family: Inter Regular;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    max-width: 100%;
  }

  .Center {
    text-align: left;
  }

  .ButtonsContainer {
    flex-direction: row;
    gap: 16px;
    padding: 0 32px;
    width: calc(100% - 64px);
  }

  .MoonIcon {
    height: 37px;
    width: 43px;
    margin-left: 12px;
    margin-bottom: 8px;
  }
}

.ButtonBlue {
  margin-top: 30px;
  background-color: #003fff !important;
  width: 80%;
  max-width: 309px;
}

.ButtonBlue:hover {
  background-color: #023ae0 !important;
}

.textProtected {
  margin-top: 24px;
  color: #003FFF !important;
  width: 80%;
  max-width: 309px;
}

.Title {
  font-size: 20px;
  font-family: 'Poppins Medium';
  color: #212121;
  margin-top: 32px;
  margin-bottom: 16px;
  line-height: 28px;
  text-align: center;
}

.Description {
  text-align: center;
  font-family: 'Inter Regular';
  font-size: 14px;
  line-height: 20px;
  max-width: 607px;
  letter-spacing: 0.0025em;
  color: #757575;
  margin-top: 0;
  margin-bottom: 0;
}