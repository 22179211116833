.Logo img {
  width: auto;
  height: 24px;
  margin-left: 38px;
}

.MenuContent {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LeftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.RightContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  margin-left: 20px;
}

.HeaderButton {
  width: 100%;
}

.CenterHeader {
  width: 100%;
}

.MainContainer {
  background-color: white;
  width: 100%;
  height: 56px;
  /* position: absolute; */
  z-index: 100;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.08);
}

.MenuIcon {
  top: 25px;
  width: 20px;
  height: 35px;
  position: absolute;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.MenuIcon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: black;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.MenuIcon span:nth-child(1) {
  top: 4px;
}

.MenuIcon span:nth-child(2) {
  top: 14px;
}

.MenuIcon span:nth-child(3) {
  top: 24px;
}

.MenuIcon.Open span:nth-child(1) {
  top: 13px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.MenuIcon.Open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.MenuIcon.Open span:nth-child(3) {
  top: 13px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.Fixed {
  position: fixed;
}
