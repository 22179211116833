.PopUpFullModal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
  z-index: 9999;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.3);
}
