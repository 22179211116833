.ContainerBranch {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 15px;
  padding: 0 16px 0 16px;
}

.discount {
  width: 20px;
  left: 10px;
  top: 3px;
  position: relative;
}


.Title {
  margin: 8px 0 0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: center;
  color: #616161;
}

.FilterContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  padding: 0 8px 0 8px;
  align-items: flex-start;
  width: calc(100% - 16px);
}

.Filter {
  display: none;
  margin: 20px 0 12px 8px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #212833;
}

.Dropdown {
  max-width: 100%;
}

.ApplyFilterButton {
  width: calc(100% - 32px);
  margin: 0;
}

.Modal {
  width: 80%;
  height: 50%;
  background-color: #ffffff;
  border-radius: 21px;
}

.ItemContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
  margin-top: 24px;
}

.KeyCenter {
  margin: 24px 0 0 0;
  font-family: Inter Bold;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
}

.Schedule {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #424242;
}

.State {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.CenterContainer {
  display: flex;
  width: calc(100% - 32px);
  height: 100%;
  padding: 20px 16px 16px 16px;
  border-radius: 16px;
  border: 1px solid #BFBFBF;
  background-color: #ffffff;
  outline: none;
}

.First {
  margin-top: 16px;
}

.Center {
  margin: 0;
  font-family: Work Sans Bold;
  font-size: 16px;
  font-weight: normal;
  line-height: 18.77px;
  text-align: left;
  color: #000000;
}

.Promo {
  margin-top: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: left;
  color: #e61e4d;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.Address {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #424242;
}

.Icon {
  height: 20px;
  width: 20px;
  margin: 2px 8px 0 0;
}

.IconPromo {
  height: 16px;
  width: auto;
  margin: 8px 8px 0 0;
}

.MoonIcon {
  display: none;
}

.MoonIconMobile {
  width: 25.32px;
  height: 21.94px;
  margin-left: 4px;
  display: inline;
}

.TitleName {
  margin: 0 auto 0 auto;
  font-family: Work Sans Bold;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
  max-width: 774px;
}

.IconContainer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.SelectButton {
  font-size: 14px;
  margin-top: 16px;
}

.EmptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}

.EmptyContainer p {
  font-family: Work Sans Regular;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  margin: 24px 0 0 0;
}

.EmptyContainer>img {
  margin-left: 12px;
  width: 32.77px;
  height: 28.4px;
}

.AddressPrimaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.FilterMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  margin: 16px 8px 0 8px;
  align-items: center;
  width: calc(100% - 32px);
  border-radius: 8px;
  border: 1px solid #BFBFBF;
}

.FilterMobile>img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.FilterMobile>span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}


.FilterOpenMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  margin: 16px 8px;
  align-items: center;
  width: calc(100% - 32px);
  border-bottom: 1px solid #BFBFBF;
}

.FilterOpenMobile>img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.FilterOpenMobile>span {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
}

.HideFilters {
  display: none;
}

.BranchesContainer {
  margin-top: 24px;
}
 
@media(min-width: 700px) {

  .MoonIcon {
    height: 37px;
    width: 43px;
    margin-left: 12px;
    display: inline;
  }

  .MoonIconMobile {
    display: none;
  }

  .ItemContainer {
    grid-template-columns: 1fr 1fr;
  }

  .ContainerBranch {
    margin-bottom: 83px;
  }

  .FilterContainer {
    padding: 0;
    width: 100%;
    gap: 16px;
    flex-direction: row;
    align-items: center;
  }

  .Filter {
    display: inline;
    margin: 48px 0 32px 0;
    font-size: 24px;
  }

  .FilterMobile {
    display: none;
  }

  .HideFilters {
    display: flex;
  }

  .FilterOpenMobile {
    display: none;
  }

  .ApplyFilterButton {
    width: auto;
    min-width: 100px;
    margin: 0;
  }

  .TitleName {
    font-size: 40px;
    line-height: 56px;
    margin: 0 auto 0 auto;

  }

  .Title {
    margin: 28px 0 0;
    font-size: 24px;
  }

  .IconContainer {
    margin-top: 12px;
  }

  .CenterContainer {
    width: calc(100% - 32px);
    padding: 16px;
  }

  .Center {
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
  }

  .Address {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }

  .Schedule {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
  }

  .Promo {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 24px;
    letter-spacing: normal;
  }

  .IconPromo {
    height: 22px;
    width: auto;
    margin: 4px 12px 0 0;
  }

  .Icon {
    height: 24px;
    width: 24px;
    margin: 4px 12px 0 0;
  }

  .SelectButton {
    font-size: 16px;
    margin-top: 32px;
  }


  .EmptyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 156px 0 96px 0;
  }

  .EmptyContainer p {
    font-family: Work Sans Regular;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    max-width: 418px;
    margin: 24px 0 0 0;
  }

  .EmptyContainer>img {
    width: 43.05px;
    height: 37.31px;
  }

}

@media(min-width: 1024px) {
  
  .ItemContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ContainerBranch {
    padding: 0 24px 0 24px;
    max-width: 1024px;
    width: 100%;
  }
}


@media(min-width: 1440px) {

  .ContainerBranch {
    max-width: 1200px;
    padding: 0 120px 0 120px;
  }
}