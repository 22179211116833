#banner {
    top: 0;
    position: sticky;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #949CE3;
    transition: .3s ease-in-out;
    z-index: 101;
}

.hide-banner {
    transform: translateY(-52px);
}

#banner .banner-content {
    flex: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
    font-weight: Bold;
    font-size: 16px;
    color: white;
}

#banner .container-counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#banner .title-banner {
    padding-right: 16px;
    text-align: center;
}
#banner .title {
    padding-right: 16px;
    text-align: center;
    text-align: center;
    font-size: 16px;
    padding: 0;
}

#banner .separator {
    padding: 0 8px;
}
.buenfinLogo{
    margin-right: 8px;

}

.buenfin--title{
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}

.timer-container{
    margin-left: 5px;
}

@media (max-width: 840px) {
    #banner {
        height: 80px;
    }
    .hide-banner {
        transform: translateY(-108px);
    }
    #banner .banner-content {
        flex-direction: column;
        padding: 0 34px;
        display: flex;    
        flex-direction: column;
    }
    #banner img {
        display: none;
    }
    #banner .title-banner {
        padding: 0;
    }
    #banner .banner-content .buenfinLogo{
        width: 32px;
        height: 32px;
      }

      #banner .title {
        font-size: 14px;
    }
      .buenfin--title{
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
    }
}
@media (max-width: 400px) {
    .buenfin--title{
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
    }

    #banner .title {
        font-size: 12px;
    }
}
#banner .banner-content .buenfinLogo{
    display: block;
}

