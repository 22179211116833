.FooterContainer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

}

.Container {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 208px);
  border-top: 1px solid #BDBDBD;
  margin: 0px 104px 0 104px;
}

.Icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.Mobile {
  margin: 0;
  font-family: HelveticaNeue;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}


.Copyright {
  color: #757575;
  font-size: 14px;
  font-family: Inter Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #616161;
  min-width: 100px;
}


.FooterLink {
  padding: unset;
  text-decoration: none;
  font-family: Inter Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #616161;
  margin: 0 16px;
}
 
.Left {
  display: flex;
  padding: 48px 0;
}

.Right {
  display: flex;
  padding: 48px 0;
}


.Right>* {
  margin-left: 24px;
  cursor: pointer;

}

.Right>a:nth-child(1) {
  margin-left: unset;
  cursor: pointer;
}




@media (max-width: 1024px) {

  .Container {    
    width: 100%;
    margin: 0;
    padding: 0 24px;
    width: calc(100% - 48px);
  } 

}

@media (max-width: 700px) {

  .Container {
    flex-direction: column;
    width: 100%;
    margin: 32px 0 0 0;
    padding: 0;
  } 
  .Copyright {   
    font-size: 12px;
    line-height: 16px;
    min-width: 90px;
  }
  
  .Left {
    padding: 38px 8px 0 8px;
    justify-content: space-evenly;
  }

  .Right {
    padding: 24px 0 48px 0;
    justify-content: center;
    width: 100%;
  }
  .FooterLink {
    font-size: 12px;
    line-height: 16px;
    margin: 0 6px;
  }
}