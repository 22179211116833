.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
}

.Wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-color: aqua;
}

@media(max-width: 680px){
  .Container {
    margin-top: 10px;
  }
}


@media(min-width: 700px){
  .Wrapper {    
    margin-top: 20px;
    max-width: 1440px;
  }
}

.ContainerLoader{
  text-align: center;
  height: calc(100vh - 256px);
}
.ContainerLoader > div{
  margin-bottom: 32px;
}

.ContainerLoader p{
  font-family: Inter Regular;
  font-size: 24px;
}