.ConfirmContainer {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.PopUp {
  min-width: 600px;
  max-width: 600px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.Wrap {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 0;
  margin-bottom: 25px;
  border-radius: 0 0 30px 30px;
  text-align: center;
  background-color: #ffffff;
}

.WrapFull {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 0;
  margin-bottom: 25px;
  border-radius: 30px;
  text-align: center;
  background-color: #ffffff;
}

.WrapHeader {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  text-align: right;
  border-radius: 30px 30px 0 0;
  background-color: #ffffff;
}

.Content {
  padding: 36px 24px 12px 24px;
  margin: 0;
}

.Title {
  margin: 0;
  padding: 48px 8px 0 8px;
  font-family: Work Sans Bold;
  font-size: 32px;
  font-weight: normal;
  line-height: 48px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: #000000;
}

.TitleCancel {
  margin: 0;
  padding: 24px;
  font-family: 'Poppins Bold';
  font-size: 32px;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  color: #212121;
}

.Dates {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Desc {
  padding: 16px 48px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.0015em;
  text-align: center;
  color: #616161;
}

.DescSmall {
  margin: 0 0 24px 0;
  font-family: 'Inter Regular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
  color: #757575;
}

.CenterContainer {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  outline: none;
  margin-top: 30px;
  padding: 33px 22px;
  border-radius: 13.5px;
}

.AreUSure {
  margin: 41px 17px 0;
  font-family: ModernEra;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Reschedule {
  padding: 16px 80px;
  margin-top: 41px;
  border-radius: 10px;
  box-shadow: 4px 4px 4px 0 rgba(0, 63, 255, 0.24);
  background-color: #0752f9;
  font-family: ModernEra;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  outline: none;
}

.TextFooter {
  margin: 36px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}

.Item {
  display: flex;
}

.Icon {
  width: 20.8px;
}


.InfoDate {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Address {
  margin: 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Name {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Center {
  margin: 0;
  justify-content: center !important;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.Error {
  color: red;
  margin: 20px 10px;
  font-family: "ModernEra";
  text-align: center;
}

.ContainerLoader {
  margin-top: 30px;
}

.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.ItemSecond {
  display: flex;
  margin-top: 24px;
}

.ModalRightButtons {
  display: flex;
  gap: 24px;
  width: calc(100% - 48px);
  margin: 48px 32px 24px 32px;
}

.ModalRightButtonsCancel {
  float: right;
  margin-right: 18px;
}

.ModalRightButtonsConfirm {
  float: right;
}

.ButtonBlue {
  background-color: #003fff !important;
}

.ButtonBlue:hover {
  background-color: #023ae0 !important;
}

.ButtonBlueDisable {
  background-color: #7BA4FF !important;
}

.CheckboxLabel {
  width: 100%;
  text-align: left;
  font-family: 'Inter Regular';
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: .04px;
  padding-right: 10px;
  margin-top: 18px;
  display: flex;
  flex-direction: row;
}

.Checkbox {
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

.CheckboxSpan {
  vertical-align: middle;
}

.ModalCancelConfirmPolicy {
  margin-bottom: 16px;
}

.ModalCancelConfirmSupport {
  margin-bottom: 20px;
}

.AdditionalComments {
  margin: 0;
  padding: 2% !important;
  width: 96% !important;
  border: solid 1px #bdbdbd;
  border-radius: 8px;
  resize: none;
  padding: 8px;
  height: 80px;
  font-family: 'Inter Regular';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.08px;
  color: #212121;
}

.AdditionalComments::placeholder {
  color: #BDBDBD;
}

.AdditionalCommentsLabel {
  color: #424242;
  font-family: 'Inter Regular';
  text-align: left;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: .04px;
  margin-bottom: 4px;
  display: block;
  margin-top: 18px;
}

.ErrorComments {
  font-family: 'Inter Regular';
  color: red;
}

.SupportButton {
  position: absolute;
  left: 50px;
  bottom: 62px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.CloseIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  top: 0;
  margin: 48px;
  cursor: pointer;
}

.SelectTitle {
  font-family: Work Sans Bold;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: -0.0025em;
  text-align: center;
  margin-bottom: 16px;
}

.SelectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 267px;
  margin: 0 auto;
}

.MoonIcon {
  height: 37px;
  width: 43px;
  margin-left: 12px;
}

.Error {
  color: red;
  margin: 20px 10px;
  font-family: "ModernEra";
  text-align: center;

}

@media (max-width: 780px) {

  .Title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-top: 32px;
  }

  .CloseIcon {
    margin: 48px 36px;
  }

  .SelectContainer {
    max-width: 100%;
  }

  .PopUp {
    width: 100%;
    min-width: 100%;
  }

  .SupportButton {
    bottom: 115px;
  }

  .ModalRightButtons {
    flex-direction: column;
    width: 100%;
    margin: 36px 0 24px 0;
  }

  .Desc {
    padding: 16px 0;
  }
}

@media (max-width: 380px) {
  .Wrap {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .WrapFull {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .WrapHeader {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .ConfirmContainer {
    align-items: baseline;
  }
}

@media (max-width: 320px) {
  .Content {
    padding: 12px;
    margin: 0;
  }
}