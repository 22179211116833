.Container {
  display: flex;
  flex-direction: column;
  margin: 14px 24px;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
}

.ContainerBody {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.ItemStart {
  margin-right: 6.5px;
  width: 15.68px;
  height: 15.68px;
}

.ContainerName {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Images {
  width: 56px;
  height: 56px;
  margin-right: 16px;
}

.Name {
  margin: 0;
  font-family: Inter Regular;
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.Source {
  margin: 0;
  font-family: Inter Regular;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #212121;
}

.Description {
  margin: 28px 0 0 0;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.OkBlue {
}